import dayjs from 'dayjs';
import Config from '@/config/config';
import { getAuth } from '../auth/auth.helpers';
import { Base64 } from 'js-base64';

import Vue from 'vue';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueToast from 'vue-toast-notification';
Vue.use(VueToast);

import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');

const CONFIG = new Config();

export const listBoletasNoMatching = async (mesRef, sigla, boleta) => {
  var axios = require('axios');
  var data = { mesRef: mesRef, sigla: sigla, boleta: boleta};

  const auth = getAuth();
  var config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRABACKOFFICE}/backoffice/comercializadora/matching/listBoletasNoMatching`,
    headers: {
      Bearer: auth.token
    },
    data: data
  };

  console.log(config)

  return axios(config)
    .then(function (response) {
      if (response.data.status) {
        Vue.$toast.success(response.data.msg, {
          position: 'top-right',
          duration: 5000
        });
      } else {
        Vue.$toast.error(response.data.msg, {
          position: 'top-right',
          duration: 5000
        });
      }

      return response;
    })
    .catch(function (error) {
      console.log(error);
      Vue.$toast.error("Erro", {
        position: 'top-right',
        duration: 5000
      });

      return null;
    });
};

export const vinculaBoletaContrato = async (idBoleta, idContrato) => {
    var axios = require('axios');
    var data = { idBoleta: idBoleta, idContrato: idContrato };
  
    const auth = getAuth();
    var config = {
      method: 'post',
      url: `${CONFIG.API_URL_LIBRABACKOFFICE}/backoffice/comercializadora/matching/vinculaBoletaContrato`,
      headers: {
        Bearer: auth.token
      },
      data: data
    };
  
    return axios(config)
      .then(function (response) {
        if (response.data.status) {
          Vue.$toast.success(response.data.msg, {
            position: 'top-right',
            duration: 5000
          });
        } else {
          Vue.$toast.error(response.data.msg, {
            position: 'top-right',
            duration: 5000
          });
        }
  
  
        return response;
      })
      .catch(function (error) {
        console.log(error);
        Vue.$toast.error("Erro", {
          position: 'top-right',
          duration: 5000
        });
  
        return null;
      });
  };
  
<template>
    <div v-if="dados && !loading">
        <b-table bordered small :fields="fields" :items="dados" class="custom-table-2" :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc">

            <template #cell(mostraContratos)="row">
                <b-icon icon="chevron-bar-expand" scale="1" @click="row.toggleDetails" class="icon"></b-icon>
            </template>

            <template #row-details="row">
                <b-card>
                    <TableContrato :dados="row.item" @vinculaContrato="vinculaContrato"></TableContrato>
                </b-card>
            </template>

            <template #cell(boleta.sys.inicio)="data">
                <p class="data">{{ data.value }}</p>
            </template>
            <template #cell(boleta.sys.fim)="data">
                <p class="data">{{ data.value }}</p>
            </template>

            <template #cell(boleta.sys.volume)="data">
                <p>{{ formatNumber(data.value, 6) }}</p>
            </template>

            <template #cell(boleta.sys.siglaPerfilParte)="data">
                <p class="perfil">{{ data.value }}</p>
            </template>

            <template #cell(boleta.sys.siglaPerfilContraParte)="data">
                <p class="perfil">{{ data.value }}</p>
            </template>
        </b-table>
    </div>
</template>

<script>
import TableContrato from './TableContrato.vue';

export default {
    data() {
        return {
            fields: [{ key: 'boleta.codBoleta', label: 'Boleta', headerTitle: 'Código Boleta', sortable: true },
            { key: 'boleta.sys.tipoContrato', label: 'PC', headerTitle: 'Período Contrato' },
            { key: 'boleta.sys.tipoEnergia', label: 'Fonte' },
            { key: 'boleta.sys.codigoPerfilParte', label: 'CodP', headerTitle: 'Código Perfil Parte' },
            { key: 'boleta.sys.siglaPerfilParte', label: 'SiglaP', headerTitle: 'Sigla Perfil Parte' },
            { key: 'boleta.sys.codigoPerfilContraParte', label: 'CodCP', headerTitle: 'Código Perfil Contra Parte' },
            { key: 'boleta.sys.siglaPerfilContraParte', label: 'SiglaCP', headerTitle: 'Sigla Perfil Contra Parte', sortable: true },
            {
                key: 'boleta.sys.nomeFantasia',
                label: 'Nome',
                headerTitle: 'Nome Fantasia'
            },
            { key: 'boleta.sys.tipoEnergia', label: 'Tipo Energia' },
            { key: 'boleta.sys.submercado', label: 'Sub', headerTitle: 'Submercado' },
            { key: 'boleta.sys.inicio', label: 'Data Ini' },
            { key: 'boleta.sys.fim', label: 'Data Fim' },
            { key: 'boleta.sys.volume', label: 'Volume' },
            { key: 'mostraContratos', label: 'Contratos', headerTitle: 'Visualizar Contratos' }],
            sortBy: 'boleta.codBoleta',
            sortDesc: false
        }
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        dados: {
            type: Array,
            default: null
        }
    },
    components: {
        TableContrato
    },
    methods: {
        vinculaContrato(ids) {
            this.$emit('vinculaContrato', { idBoleta: ids.idBoleta, idContrato: ids.idContrato });
        },
        formatNumber(number, decimals) {
            // Verifica se o número é zero
            if (number == 0) {
                return 0;
            } else {
                // Caso contrário, formata o número com o número especificado de casas decimais
                return number.toLocaleString('pt-BR', { minimumFractionDigits: decimals });
            }
        }
    }
};
</script>

<style scoped>
.data {
    width: 100px;
}

.custom-table-2 {
    font-size: 14px;
}

.icon {
    margin-left: 10px;
}

.perfil {
  width: 110px;
}
</style>
<template>
    <div>
        <PageHeader :title="'Matching Boletas e Contratos:'" :items="breadcrumb" />

        <b-card class="mb-3 mt-3">
            <b-container fluid class="ml-3 mt-2">
                <b-col align-v="center" class="my-4">
                    <b-row>
                        <b-col md="2">
                            <b-form-group label="Mês de Referência" label-for="filtroMesRef" class="form">
                                <b-form-select id="filtroMesRef" v-model="filtroMesRef" :options="meses"
                                    @change="atualizarDataRef" />
                            </b-form-group>
                        </b-col>

                        <b-col md="2">
                            <b-form-group label="Ano de Referência" label-for="filtroAnoRef" class="form">
                                <b-form-input id="filtroAnoRef" type="number" v-model="filtroAnoRef"
                                    @change="atualizarDataRef" />
                            </b-form-group>
                        </b-col>

                        <b-col md="2">
                            <b-form-group label="Tipo Operação" label-for="tipoOperacao" class="form">
                                <b-form-select id="operacoes" :options="operacoes" v-model="venda" @change="muda" />
                            </b-form-group>
                        </b-col>

                        <b-col md="2">
                            <b-form-group label="Sigla Perfil ContraParte" label-for="siglaPerfil" class="form">
                                <b-form-input id="siglaPerfil" type="text" v-model="sigla" @keyup.enter="fetchData" />
                            </b-form-group>
                        </b-col>

                        <b-col md="2">
                            <b-form-group label="Código Boleta" label-for="codBoleta" class="form">
                                <b-form-input id="codBoleta" type="text" v-model="boleta" @keyup.enter="fetchData" />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="3">
                            <b-button @click="fetchData" class="b"
                                v-if="hasPermission('backoffice_edit')">Listar</b-button>
                            <b-spinner v-if="loading" text-align="center" variant="success" label="Spinning"
                                class="ml-2 mt-3" />
                        </b-col>
                    </b-row>
                </b-col>
            </b-container>
        </b-card>

        <TableBoleta :loading="loading" :dados="dadosFiltrados" @vinculaContrato="vinculaContrato"></TableBoleta>
    </div>
</template>

<script>
import Vue from 'vue';
import VueMask from 'v-mask';
import PageHeader from '@/components/page-header';
import meses from '@/utils/meses';
import TableBoleta from './TableBoleta.vue';
import { listBoletasNoMatching, vinculaBoletaContrato } from './matchingManual.service';
import state from '@/features/auth/auth.store';
import { hasPermission } from '@/features/auth/auth.acl';

Vue.use(VueMask);

export default {
    data() {
        return {
            mesRefInput: '',
            meses: meses(),
            mesRef: '',
            filtroMesRef: new Date().getMonth() + 1,
            filtroAnoRef: new Date().getFullYear(),
            breadcrumb: [
                {
                    text: 'Backoffice',
                    href: '#'
                },
                {
                    text: 'Matching',
                    active: true
                }
            ],
            operacoes: [
                { value: true, text: 'Vendas' },
                { value: false, text: 'Compras' }],
            venda: true,
            dados: null,
            dadosVenda: null,
            dadosCompra: null,
            loading: false,
            dadosFiltrados: null,
            permissions: state.state.acl,
            sigla: '',
            boleta: ''
        }
    },
    created() {
        this.atualizarDataRef();
    },
    components: {
        PageHeader, TableBoleta
    },
    methods: {
        muda() {
            if (this.venda) {
                this.dadosFiltrados = this.dadosVenda;
            } else {
                this.dadosFiltrados = this.dadosCompra;
            }
        },
        filtro() {
            this.dadosVenda = this.dados.filter(item => item.boleta.tipoBoleta === 'V');
            this.dadosCompra = this.dados.filter(item => item.boleta.tipoBoleta === 'C');
            this.muda();
        },
        fetchData() {
            this.loading = true;

            listBoletasNoMatching(this.mesRefInput, this.sigla, this.boleta)
                .then((response) => {
                    if (response.status) {
                        this.dados = response.data.content;
                        this.filtro();
                    } else {
                        console.error(
                            'Resposta do servidor não está no formato esperado:',
                            response
                        );
                    }
                })
                .catch((error) => {
                    console.error('Erro ao buscar dados:', error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        atualizarDataRef() {
            if (this.filtroMesRef && this.filtroAnoRef) {
                const mesIndexZeroBase = this.filtroMesRef - 1;
                this.mesRefInput = `${this.filtroAnoRef}-${String(
                    mesIndexZeroBase + 1
                ).padStart(2, '0')}-01`;
            }
        },
        vinculaContrato(ids) {
            if (this.permissions.indexOf("backoffice_edit") == -1) {
                return;
            }

            this.loading = true;
            vinculaBoletaContrato(ids.idBoleta, ids.idContrato)
                .then((response) => {
                    if (response.status) {
                        this.dados = response.data.content;
                    } else {
                        console.error(
                            'Resposta do servidor não está no formato esperado:',
                            response
                        );
                    }
                })
                .catch((error) => {
                    console.error('Erro ao buscar dados:', error);
                })
                .finally(() => {
                    this.loading = false;
                    this.fetchData();
                });
        },
        hasPermission(requiredAcl) {
            return hasPermission(requiredAcl);
        }
    }
}
</script>

<style scoped>
.b {
    margin-top: 27px;
    width: 10vw;
}
</style>
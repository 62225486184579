<template>
    <div v-if="dados">
        <b-table bordered small :fields="fields" :items="contratos" class="custom-table">

            <template #cell(matching)="data" v-if="hasPermission('backoffice_edit')">
                <b-button @click="vincular(data.item)" class="b">Vincular</b-button>
            </template>
        </b-table>
    </div>
</template>

<script>
import {hasPermission} from '@/features/auth/auth.acl';

export default {
    data() {
        return {
            fields: [
                { key: 'cliqccee', label: 'Cliqccee' },
                { key: 'suprimentoIni', label: 'Supri Ini', headerTitle: 'Suprimento Inicial' },
                { key: 'suprimentoFim', label: 'Supri Fim', headerTitle: 'suprimento Final' },
                { key: 'statusContrato', label: 'Status' },
                { key: 'montanteMWm', label: 'MWM', headerTitle: 'Montante MWm' },
                { key: 'vigenciaIniMontante', label: 'Vig Ini', headerTitle: 'Vigência Inicial' },
                { key: 'vigenciaFimMontante', label: 'Vig Fim', headerTitle: 'Vigência Final' },
                { key: 'matching', label: 'Matching' }],
            contratos: []
        }
    },
    props: {
        dados: Object
    },
    methods: {
        vincular(data) {
            this.$emit('vinculaContrato', { idBoleta: this.dados.boleta.id, idContrato: data.id });
        },
        hasPermission(requiredAcl){
            return hasPermission(requiredAcl);
        }
    },
    created() {
        this.contratos.push(...this.dados.contratos);
    }
};
</script>

<style scoped>
.custom-table {
    font-size: 12px;
}

.b {
    height: 4vh;
    font-size: 12px;
}
</style>